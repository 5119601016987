body {
    background-color: #282c34;
}

.App-header-corgi {
    background-color: #282c34;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .main-content{
    background-color: #282c34;
    color: white;
    padding: 20px;
    display: flex;
    font-size: 24px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .intro-section {
    font-size:32px;
    font-weight: 500;
    overflow: hidden;   
    display: flex;
    flex: 1 0;
    flex-direction: column;
    width: 50%;
}

 .intro-section p {
    margin: 0;
    padding: 0;
    font-size: 24px !important;
 }

 .corgi-sample {
    border: solid;
    display: flex;
    flex: 1 0;
    width: 50%;
    height: fit-content;
    margin: 20px;
    max-width: 751px;
    margin-top: 55px;
 }

 @media all and (max-width: 1124px) {
    .main-content {
        flex-direction: column-reverse;
    }
    .corgi-sample {
        width: 100%;
      
        margin-left: auto;
        margin-right: auto;
       
    }

    .intro-section {
        width: 100%;
        margin-bottom: 40px;
    }
}

.privacy-policy-link {
    font-size: 14px;
    color: white;
    position: static;
    bottom: 0;
    right: 0;
}

.privacy-policy-link a:any-link {
color: white;

}
  
  